import { Link, Button, Flex } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { mediaQuery } from 'lib/styledSystem';
import { rem } from 'polished';

export const SubmitButton = styled(Button)`
  &:hover {
    color: ${themeGet('colors.white')};
  }
`;

export const FindOutMoreLink = styled(Link)`
  font-size: ${themeGet('fontSizes.base')};
  color: ${themeGet('colors.greys.charcoal')};
  text-decoration: underline;
`;

export const LoginContainer = styled(Flex)`
  align-items: flex-end;
  flex-grow: 1;
  align-items: center;
  padding-right: 0;
  width: 100%;

  ${mediaQuery.minWidth.sm} {
    padding-right: ${rem('52px')};
    width: 50%;
  }

  ${mediaQuery.minWidth.md} {
    padding-right: 0;
  }
`;
