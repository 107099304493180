import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Flex, Box, Text, Icon, Link, NakedButton } from '@qga/roo-ui/components';
import { useDataLayer } from 'hooks/useDataLayer';
import { DisclaimerItemLogo } from 'components/Footer/DisclaimerItems';
import { qantasQff } from '@qga/roo-ui/logos';
import { useIsAuthenticated, useLogout } from 'lib/oauth';
import { getFirstName, getLastName, getMemberId, getTitle } from 'store/user/userSelectors';

const Wrapper = styled(Box)`
  background-color: ${themeGet('colors.greys.porcelain')};
  border-radius: ${themeGet('radii.default')};
  height: 65px;
`;

export const QffCTALink = styled(Link)`
  font-weight: ${themeGet('fontWeights.bold')};
  font-size: ${themeGet('fontSizes.sm')};
`;

export const QffCTAButton = styled(NakedButton)`
  font-weight: ${themeGet('fontWeights.bold')};
  font-size: ${themeGet('fontSizes.sm')};
  color: ${themeGet('colors.brand.primary')};
`;

const truncateText = (text, limit) => {
  return text?.length > limit ? text.substring(0, limit - 1) + '...' : text;
};

const QffWidget = () => {
  const { emitInteractionEvent } = useDataLayer();
  const { logout } = useLogout();

  const isAuthenticated = useIsAuthenticated();
  const qffNumber = useSelector(getMemberId);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const title = useSelector(getTitle);

  const qffDetails = `${title} ${firstName} ${lastName}`;
  const qffDetailsString = truncateText(qffDetails, 20);
  const joinForFreeUrl = 'https://www.qantas.com/au/en/frequent-flyer/discover-and-join/join-now.html/code/AIRBNB';
  const eventValue = isAuthenticated ? 'Logout CTA' : 'Join for free CTA';
  const CTAMessage = isAuthenticated ? 'Log out' : 'Join for free';

  const handleOnClick = useCallback(() => {
    emitInteractionEvent({ type: 'Airbnb - Qff', value: eventValue });
  }, [emitInteractionEvent, eventValue]);

  const logoutSSO = useCallback(() => {
    logout();
    handleOnClick();
  }, [logout, handleOnClick]);

  return (
    <Wrapper width={['100%', '100%', '45%']} px={[1, 1, 4]}>
      <Flex data-testid="qff-widget" width={'100%'} justifyContent="space-around">
        <Flex data-testid="logo" alignItems="center" width={[!isAuthenticated ? '10%' : 'auto', !isAuthenticated ? '10%' : 'auto', 'auto']}>
          {isAuthenticated && <Icon name="roo" color="brand.primary" />}
          {!isAuthenticated && (
            <Box pt={4}>
              <DisclaimerItemLogo src={qantasQff} alt="Qantas QFF Logo" role="img" aria-label="Qantas QFF Logo" />
            </Box>
          )}
          {isAuthenticated && (
            <Flex flexDirection={'column'} px={4} py={2} data-testid="member-name">
              <Text fontWeight="bold">{qffDetailsString}</Text>
              <Text color="greys.steel">{qffNumber}</Text>
            </Flex>
          )}
        </Flex>

        <Flex
          pr={1}
          alignItems="center"
          justifyContent="flex-end"
          width={[!isAuthenticated ? '90%' : 'auto', !isAuthenticated ? '90%' : 'auto', 'auto']}
        >
          <Flex data-testid="qff-widget" alignItems="center" pr={2}>
            {!isAuthenticated && <Text>Not a member?</Text>}
            {isAuthenticated && <Text>Not you?</Text>}
          </Flex>

          {!isAuthenticated && (
            <QffCTALink
              data-testid={'join-for-free-cta'}
              href={joinForFreeUrl}
              rel={'noopener noreferrer'}
              target={'_blank'}
              onClick={handleOnClick}
            >
              {CTAMessage}
            </QffCTALink>
          )}

          {isAuthenticated && (
            <QffCTAButton data-testid="log-out-cta" onClick={logoutSSO}>
              {CTAMessage}
            </QffCTAButton>
          )}
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default QffWidget;
