import pino from 'pino';

const redactions = ['req.headers.authorization', 'req.headers.cookie'];

const logger = pino({ redact: redactions });

export const proxyLoggerWrapper = () => {
  return {
    log: (...args) => logger.info(...args),
    info: (...args) => logger.info(...args),
    debug: (...args) => logger.debug(...args),
    warn: (...args) => logger.warn(...args),
    error: (...args) => logger.error(...args),
  };
};

export const customLogLevel = (res, err) => {
  if (res.statusCode >= 400 && res.statusCode < 500) return 'warn';
  if (res.statusCode >= 500 || err) return 'error';
  return 'info';
};

export default logger;
